<app-accessibility-toolbar *ngIf="portalLive == true"></app-accessibility-toolbar>

<div class="container-fluid contact-container">
    <div class="contact-welcome-card d-flex flex-column align-items-start align-self-stretch">
        <div class="contact-welcome-card-content d-flex justify-content-between align-items-center align-self-stretch">
            <span>Contact</span>
        </div>
    </div>

    <div class="contact-content d-flex flex-column align-items-start align-self-stretch">
        <div class="contact-content-container d-flex flex-column align-self-stretch">
            <div class="row">
                <div class="contact-content-container-details col-lg-6 d-flex flex-column justify-content-center">
                    <p>{{ appName }}</p>
                    <div class="d-flex flex-column">
                        <p class="contact-title">Adresa: <span class="contact-text">Bd. George Coșbuc, nr. 6-16, Sector 4</span></p>
                        <p class="contact-title">Call center: <span class="contact-text">021.9441</span></p>
                        <p class="contact-title">Telefon: <span class="contact-text">021.335.92.30 / 021.335.92.36</span></p>
                        <p class="contact-title">Fax: <span class="contact-text">021.337.07.90</span></p>
                        <p class="contact-title">E-mail: <span class="contact-text">contact@ps4.ro</span></p>
                    </div>
                </div>

                <div class="col-lg-6 d-flex">
                    <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d340.95477998577803!2d26.088790751519795!3d44.42098738647064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1ff734fba5a85%3A0xc1b4651cc5a5b6f9!2sPrim%C4%83ria%20Sectorului%204!5e0!3m2!1sen!2sro!4v1702386227933!5m2!1sen!2sro" 
                    width="100%" height="350" style="border: 0;" allowfullscreen loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>
            </div>
    
            <div>
                <mat-divider ></mat-divider>

                <div class="row">
                    <div class="contact-content-container-details col-lg-6 d-flex flex-column justify-content-center">
                        <p>Societate Totul Verde S.A.</p>
                        <p class="contact-title">Call center: <span class="contact-text">021.9441</span></p>
                        <p class="contact-title">Fax: <span class="contact-text">021.33.22.446</span></p>
                        <p class="contact-title">E-mail: <span class="contact-text">info@totulverde.ro</span></p>
                    </div>
    
                    <div class="col-lg-6 d-flex">
                        <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2851.8701104393463!2d26.118303312385237!3d44.37425747095644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1ffb678e7adaf%3A0xe67f946f54ac4445!2sTotul%20Verde.%20SA!5e0!3m2!1sen!2sro!4v1705044276884!5m2!1sen!2sro" 
                        width="100%" height="350" style="border: 0;" allowfullscreen loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                        </iframe>
                    </div>
                </div>

                <mat-divider></mat-divider>

                <div class="row">
                    <div class="contact-content-container-details col-lg-6 d-flex flex-column justify-content-center">
                        <p><a href="https://delta-registratura.webproductionlabs.com/" target="_blank">Direcția Mobilitate Urbană</a></p>
                        <p class="contact-title">Adresa: <span class="contact-text">Bd. Metalurgiei nr. 12-18, Grand Arena, et. 1 parcări publice și de reședință</span></p>
                        <p class="contact-title">Call center: <span class="contact-text">021.9441</span></p>
                        <a href="https://www.mobilitateurbana4.ro/registratura-online/" target="_blank"><strong>Registratură online</strong></a>
                    </div>
                    
                    <div class="col-lg-6 d-flex">
                        <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2851.8262581774643!2d26.11325942682618!3d44.375157938883255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1ff58e9182769%3A0x9775866b4da97146!2sDirectia%20MObilitate%20Urbana%20Sector%204!5e0!3m2!1sen!2sro!4v1705045011941!5m2!1sen!2sro" 
                        width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                        </iframe>
                    </div>
                </div>

                <mat-divider></mat-divider>

                <div class="row">
                    <div class="contact-content-container-details col-lg-6 d-flex flex-column justify-content-center">
                        <p>Direcția Generală de Administrare a Unităților de Învățământ și de Sport Sector 4</p>
                        <p class="contact-title">Telefon: <span class="contact-text">0786.883.180</span></p>
                        <p class="contact-title">E-mail: <span class="contact-text">office@dgauis.ro</span></p>
                    </div>

                    <div class="col-lg-6 d-flex">
                        <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2849.58994750136!2d26.08661501238723!3d44.42105947095546!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1fe416f8552f3%3A0x7d5c8744fc1edfe8!2zRGlyZWPFo2lhIEdlbmVyYWzEgyBkZSBBZG1pbmlzdHJhcmUgYSBVbml0xIPFo2lsb3IgZGUgw45udsSDxaPEg23Dom50IMWfaSBkZSBTcG9ydCBTZWN0b3IgNA!5e0!3m2!1sen!2sro!4v1705045149248!5m2!1sen!2sro" 
                        width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                        </iframe>
                    </div>
                </div>

                <mat-divider></mat-divider>
                
                <div class="row">
                    <div class="contact-content-container-details col-lg-6 d-flex flex-column justify-content-center">
                        <p>Centrul Cultural pentru UNESCO „Nicolae Bălcescu”</p>
                        <p class="contact-title">Adresa: <span class="contact-text">Strada 11 Iunie nr. 41</span></p>
                        <p class="contact-title">Telefon: <span class="contact-text">021.336.4340</span></p>
                        <p class="contact-title">E-mail: <span class="contact-text">ccnb4@yahoo.com</span></p>
                    </div>

                    <div class="col-lg-6 d-flex">
                        <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2849.5837331868174!2d26.0932121123873!3d44.421186970955425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1ff12062f08e1%3A0xfa8140287b2b9c9!2sCentrul%20Cultural%20pentru%20UNESCO%20%22Nicolae%20Balcescu%22!5e0!3m2!1sen!2sro!4v1705045206789!5m2!1sen!2sro" 
                        width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                        </iframe>
                    </div>
                </div>

                <mat-divider></mat-divider>
                
                <div class="row">
                    <div class="contact-content-container-details col-lg-6 d-flex flex-column justify-content-center">
                        <p>Direcția generală de asistență socială și protecția copilului</p>
                        <ul>
                            <li>Protecția Copilului</li>
                            <p class="contact-title">Adresa: <span class="contact-text">Bd Metalurgiei, nr. 12-18, Grand Arena, etaj 1</span></p>
                            <p class="contact-title">Telefon: <span class="contact-text">0372.715.100 / 0372.715.101</span></p>
                            <p class="contact-title">Fax: <span class="contact-text">0372.713.885 / 0372.715.105</span></p>
                        </ul>
                        <ul>
                            <li>Asistenţă Socială</li>
                            <p class="contact-title">Adresa: <span class="contact-text">Şoseaua Olteniţei nr. 252-254, parter</span></p>
                            <p class="contact-title">Telefon: <span class="contact-text">0372.715.100 / 0372.715.101</span></p>
                        </ul>
                    </div>

                    <div class="col-lg-6 d-flex col-lg-6 d-flex flex-column justify-content-center">
                        <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2851.8531485163967!2d26.11655621238528!3d44.374605770956485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1ffae03121bf1%3A0x5bf4625defa5dbdf!2zRGlyZWPIm2lhIEdlbmVyYWzEgyBkZSBBc2lzdGVuyJvEgyBTb2NpYWzEgyDImWkgUHJvdGVjyJtpYSBDb3BpbHVsdWkgU2VjdG9yIDQ!5e0!3m2!1sen!2sro!4v1705045297875!5m2!1sen!2sro" 
                        width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                        </iframe>
                        
                        <iframe class="pt-2"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2851.2974813579267!2d26.135933312385784!3d44.38601477095617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1fe73e1e01c43%3A0x546bfd129c7b2ba2!2zRGlyZWPFo2lhIGRlIEFzaXN0ZW7Fo8SDIFNvY2lhbMSDIFNlY3RvciA0!5e0!3m2!1sen!2sro!4v1705045365210!5m2!1sen!2sro"
                        width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                        </iframe>
                    </div>
                </div>

                <mat-divider></mat-divider>
                
                <div class="row">
                    <div class="contact-content-container-details col-lg-6 d-flex flex-column justify-content-center">
                        <p>Direcția Generală de Impozite și Taxe Locale Sector 4</p>
                        <p class="contact-title">Adresa: <span class="contact-text">Bd. Metalurgiei, nr. 12-18, Grand Arena, etaj 1, sector 4</span></p>
                        <p class="contact-title">Call center: <span class="contact-text">021.9441</span></p>
                        <p class="contact-title">E-mail: <span class="contact-text">office@taxeimpozite4.ro</span></p>
                    </div>

                    <div class="col-lg-6 d-flex">
                        <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11407.39433588491!2d26.100035687158194!3d44.374699500000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1ff688e5ae87d%3A0xb90473014cc5ebd9!2sImpozite%20si%20Taxe%20Locale%20Sector%204!5e0!3m2!1sen!2sro!4v1705045606523!5m2!1sen!2sro"
                        width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                        </iframe>
                    </div>
                </div>

                <mat-divider></mat-divider>
                
                <div class="row">
                    <div class="contact-content-container-details col-lg-6 d-flex flex-column justify-content-center">
                        <p>Direcţia Piețe și Gestionare Activități Comerciale Sector 4</p>
                        <p class="contact-title">Adresa: <span class="contact-text">Şoseaua Giurgiului nr. 109A</span></p>
                        <p class="contact-title">Telefon: <span class="contact-text">031.425.4141</span></p>
                        <p class="contact-title">Fax: <span class="contact-text">031.437.0698</span></p>
                        <p class="contact-title">E-mail: <span class="contact-text">contactdap4@gmail.com</span></p>
                    </div>

                    <div class="col-lg-6 d-flex">
                        <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d712.7428587593741!2d26.093496878573028!3d44.39270809818475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1fe4a978780a3%3A0x6da7dd02173e1a13!2sPia%C8%9Ba%20Progresul!5e0!3m2!1sen!2sro!4v1705045814177!5m2!1sen!2sro"
                        width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                        </iframe>
                    </div>
                </div>

                <mat-divider></mat-divider>
                
                <div class="row">
                    <div class="contact-content-container-details col-lg-6 d-flex flex-column justify-content-center">
                        <p>Direcţia Generală de Poliție Locală</p>
                        <p class="contact-title">Adresa: <span class="contact-text">Bd. Metalurgiei, nr. 12-18, Grand Arena, etaj 1</span></p>
                        <p class="contact-title">Telefon: <span class="contact-text">021.9441</span></p>
                        <p class="contact-title">Fax: <span class="contact-text">021.337.07.90</span></p>
                        <p class="contact-title">E-mail: <span class="contact-text">sesizari@politialocala4.ro</span></p>
                    </div>

                    <div class="col-lg-6 d-flex">
                        <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2851.8400775857035!2d26.118665312385247!3d44.37487417095631!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1fe14239af211%3A0x7c6c4225e4c629f9!2sDirectia%20Generala%20de%20Politie%20Locala%20Sector%204!5e0!3m2!1sen!2sro!4v1705045891004!5m2!1sen!2sro"
                        width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                        </iframe>
                    </div>
                </div>

                <mat-divider></mat-divider>

                <div class="row">
                    <div class="contact-content-container-details col-lg-6 d-flex flex-column justify-content-center">
                        <p>Compartiment Spațiu Locativ</p>
                        <p class="contact-title">Adresa: <span class="contact-text">Bd. Metalurgiei, nr. 12-18, Grand Arena, etaj 1</span></p>
                        <p class="contact-title">Telefon: <span class="contact-text">021.9441</span></p>
                    </div>

                    <div class="col-lg-6 d-flex">
                        <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5704.004171697631!2d26.11436446968687!3d44.37154738382559!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1fe1aee3736d3%3A0xc4b91bceb7cd9cae!2sGrand%20Arena%20Mall!5e0!3m2!1sen!2sro!4v1705046467112!5m2!1sen!2sro"
                        width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                        </iframe>
                    </div>
                </div>

                <mat-divider></mat-divider>
                
                <div class="row">
                    <div class="contact-content-container-details col-lg-6 d-flex flex-column justify-content-center">
                        <p>Serviciul Autoritate Tutelară</p>
                        <p class="contact-title">Adresa: <span class="contact-text">Bd. Metalurgiei, nr. 12-18, Grand Arena, etaj 1</span></p>
                        <p class="contact-title">Telefon: <span class="contact-text">021.9441 / 0372.713.881</span></p>
                        <p class="contact-title">E-mail: <span class="contact-text">autoritateatutelara@ps4.ro </span></p>
                    </div>

                    <div class="col-lg-6 d-flex">
                        <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5704.004171697631!2d26.11436446968687!3d44.37154738382559!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1fe1aee3736d3%3A0xc4b91bceb7cd9cae!2sGrand%20Arena%20Mall!5e0!3m2!1sen!2sro!4v1705046467112!5m2!1sen!2sro"
                        width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                        </iframe>
                    </div>
                </div>
            </div>

            <mat-divider></mat-divider>

            <div>
                <h2 class="text-center">Doriți să ne contactați? Lăsați-ne un mesaj mai jos:</h2>
                <form [formGroup]="formContact">
                    <div class="row">
                        <mat-form-field class="col-md-4">
                            <mat-label>Nume</mat-label>
                            <input matInput type="text" formControlName="nume" />
                            <mat-error *ngIf="!formContact.controls['nume'].valid && formContact.controls['nume'].touched">
                                Câmp obligatoriu
                            </mat-error>
                        </mat-form-field>
    
                        <mat-form-field class="col-md-4">
                            <mat-label>Prenume</mat-label>
                            <input matInput type="text" formControlName="prenume" />
                            <mat-error *ngIf="!formContact.controls['prenume'].valid && formContact.controls['prenume'].touched">
                                Câmp obligatoriu
                            </mat-error>
                        </mat-form-field>
    
                        <mat-form-field class="col-md-4">
                            <mat-label>Email</mat-label>
                            <input matInput type="email" formControlName="email" />
                            <mat-error
                                *ngIf="formContact.controls['email'] && (formContact.controls['email'].dirty || formContact.controls['email'].touched) && formContact.controls['email'].hasError('required')">
                                Câmp obligatoriu
                            </mat-error>
                            <mat-error
                                *ngIf="formContact.controls['email'] && (formContact.controls['email'].dirty || formContact.controls['email'].touched) && (formContact.controls['email'].hasError('email') || formContact.controls['email'].hasError('pattern'))">
                                Email invalid
                            </mat-error>
                        </mat-form-field>
                    </div>
                    
                    <div class="row">
                        <mat-form-field class="col-md-4">
                            <mat-label>Telefon</mat-label>
                            <button type="button" class="country-selector" mat-button matPrefix [matMenuTriggerFor]="menu">
                                <mat-icon>expand_more</mat-icon>
                                {{ currentOption.flag }} {{ currentOption.dial_code }}
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="changeCountry(optionRom)">
                                    <span class="flag-wrapper">🇷🇴</span>
                                    <span class="label-wrapper">
                                        {{ optionRom.name }} {{ optionRom.dial_code }}
                                    </span>
                                </button>
                                <mat-divider class="divider"></mat-divider>
                                <button mat-menu-item *ngFor="let option of options" (click)="changeCountry(option)">
                                    <span class="flag-wrapper">{{ option.flag }}</span>
                                    <span class="label-wrapper">
                                        {{ option.name }} {{ option.dial_code }}
                                    </span>
                                </button>
                            </mat-menu>
                            <input type="text" matInput formControlName="telefon" />
                            <mat-error *ngIf="!formContact.controls['telefon'].valid && formContact.controls['telefon'].touched">
                                Câmp obligatoriu
                            </mat-error>
                        </mat-form-field>
    
                        <mat-form-field class="col-md-4">
                            <mat-label>Adresa</mat-label>
                            <input matInput type="text" formControlName="adresa" />
                            <mat-error *ngIf="!formContact.controls['adresa'].valid && formContact.controls['adresa'].touched">
                                Câmp obligatoriu
                            </mat-error>
                        </mat-form-field>
    
                        <mat-form-field class="col-md-4">
                            <mat-label>Subiect</mat-label>
                            <input matInput type="text" formControlName="subiect" />
                            <mat-error *ngIf="!formContact.controls['subiect'].valid && formContact.controls['subiect'].touched">
                                Câmp obligatoriu
                            </mat-error>
                        </mat-form-field>
                    </div>
                    
                    <div class="row">
                        <mat-form-field class="col-md-4">
                            <mat-label>Mesaj</mat-label>
                            <textarea matInput type="text" formControlName="mesaj"></textarea>
                            <mat-error *ngIf="!formContact.controls['mesaj'].valid && formContact.controls['mesaj'].touched">
                                Câmp obligatoriu
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="contact-content-container__upload d-flex flex-column">
                        <div class="contact-content-container__upload-header d-flex flex-row justify-content-between align-items-center">
                            <p class="m-0">Anexe</p>
                            <button type="button" class="upload-btn" (click)="fileInput.click()">
                                <mat-icon class="upload-icon">cloud_upload</mat-icon>
                                ÎNCARCĂ
                            </button>
                        </div>
                        <div class="contact-content-container__upload-files" *ngIf="formContact.value.files && formContact.value.files.length > 0">
                            <div class="contact-content-container__upload-file d-flex flex-row justify-content-between align-items-center" *ngFor="let uploadedFile of formContact.value.files; let i = index">
                                <span class="contact-content-container__upload-file-name">
                                    {{ (uploadedFile.name && uploadedFile.name.length > 50) ? (uploadedFile.name | slice: 0:50) + '..' : (uploadedFile.name) }}
                                </span>

                                <mat-spinner diameter="32" *ngIf="uploading[i]"></mat-spinner>
                                <div>
                                    <button class="contact-content-container__upload-file-delete d-flex" type="button" matTooltip="Șterge fișierul încărcat" (click)="removeFile(i)">
                                        <img class="btn-img" src="assets/icons/delete.svg" alt="" />
                                    </button>
                                </div>
                            </div>
                        </div>
            
                        <input type="file" accept="image/png, image/jpeg, image/gif, image/jpg, application/pdf" id="upload-file-input-0" #fileInput
                            name="fileInput" (change)="addFile($event)" multiple style="display: none">
                    </div>

                    <div class="contact-content-container__info d-flex flex-column flex-md-row justify-content-between align-items-center">
                        <div class="contact-content-container__info-text">
                            <p class="info">
                                <mat-icon class="info-icon red-text">info</mat-icon>Câmpurile marcate cu
                                <span class="red-text">&nbsp;*&nbsp;</span>sunt obligatorii
                            </p>
                            <p class="info">
                                <mat-icon class="info-icon red-text">info</mat-icon>Fișierele atașate trebuie să fie de tip imagine sau PDF, de maxim 10MB fișierul
                            </p>
                            <div class="d-flex align-items-center">
                                <mat-checkbox formControlName="prelucrareDate" color="primary"></mat-checkbox>
                                <strong class="m-0">Sunt de acord cu prelucrarea datelor în conformitate cu politica de confidențialitate și securitate a datelor cu caracter personal.</strong>
                            </div>
                        </div>
                        <button class="btn btn-primary send-button mt-3 mt-md-0" (click)="submitForm()" type="submit"  [disabled]="!formContact.valid">
                            Trimite
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
